//MyRightModal
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withWindowSize } from "../mobileView/hoc/withWindowSize";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { connect } from "react-redux";

const styles = (theme) => ({
  dialog: {
    margin: "0px 0px 0px auto",
    right: "0px",
    left: "auto",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  myTitle: {
    padding: "5px",
    lineHeight: "2.5rem",
    float: "left",
    color: "#fff",
    cursor: "pointer",
    fontWeight: 600,
  },
  myTitleMob: {
    padding: "5px",
    lineHeight: "2.5rem",
    float: "left",
    color: "#fff",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "14px",
  },
  closeIcon: {
    padding: "10px 10px",
    color: "#ffffff",
    cursor: "pointer",
    float: "right",
    borderRadius: "50%",
  },
  dialogcontent: {
    backgroundColor: "#f6f6f6",
    padding: "1rem 0rem",
    overflow: "auto !important",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class MyPlainFullModal extends Component {
  render() {
    const { classes, children } = this.props;
    const { width } = this.props.windowSize;
    const isDesktop = width >= 768;

    return (
      <Dialog
        className={classes.dialog}
        maxWidth="sm"
        fullScreen
        open={this.props.open || false}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
        disableEnforceFocus
        disableAutoFocus
      >
        <DialogTitle
          style={{
            padding: "0px 24px",
            backgroundImage: this.props.bgColor,
            background: this.props.bgColor,
          }}
        >
          {this.props.title && (
            <Typography
              onClick={this.props.onClose}
              className={isDesktop ? classes.myTitle : classes.myTitleMob}
              variant="h4"
            >
              <ArrowBackIcon style={{ marginRight: "12px" }} />
              {this.props.title}
            </Typography>
          )}

          <Tooltip arrow title="Close">
            <IconButton
              style={{ color: "white" }}
              className={classes.closeIcon}
              size="small"
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent className={classes.dialogcontent} dividers>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

const ConnectWith = connect(mapStateToProps, null)(MyPlainFullModal);

const ConnectedWithWindowSize = withWindowSize(ConnectWith);

export default withStyles(styles)(ConnectedWithWindowSize);
